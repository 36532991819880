:root {
	--white: #ffffff;
	--black: #333333;
	--light: #f2f2f2;
	--superlight: #f6f6f6;
	--lowlight: #cccccc;
	--marker: #fbf2bd;
}

body {
	margin: 0;
	padding: 0;
	min-height: 100vh;
	position: relative;
	background-color: var(--white);
	font-family: "SpaceGrotesk", sans-serif;
	font-weight: 300;
}

@font-face {
	font-family: "SpaceGrotesk";
	font-weight: 300;
	src:
		local("SpaceGrotesk-Light"),
		url("./assets/fonts/SpaceGrotesk-Light.ttf") format("truetype");
}

@font-face {
	font-family: "SpaceGrotesk";
	font-weight: 400;
	src:
		local("SpaceGrotesk-Regular"),
		url("./assets/fonts/SpaceGrotesk-Regular.ttf") format("truetype");
}

@font-face {
	font-family: "SpaceGrotesk";
	font-weight: 500;
	src:
		local("SpaceGrotesk-Medium"),
		url("./assets/fonts/SpaceGrotesk-Medium.ttf") format("truetype");
}

@font-face {
	font-family: "SpaceGrotesk";
	font-weight: 700;
	src:
		local("SpaceGrotesk"),
		url("./assets/fonts/SpaceGrotesk-SemiBold.ttf") format("truetype");
}

@font-face {
	font-family: "SpaceGrotesk";
	font-weight: 800;
	src:
		local("SpaceGrotesk-Bold"),
		url("./assets/fonts/SpaceGrotesk-Bold.ttf") format("truetype");
}

/* - tytuły - */

.subtitle {
	width: 100%;
	display: flex;
	justify-content: center;
	margin-bottom: 36px;
}

.subtitle_box {
	width: 100%;
	margin: 0 96px;
}

@media only screen and (max-width: 768px) {
	.subtitle {
		margin-bottom: 12px;
	}

	.subtitle_box {
		margin: 0 48px;
	}
}

h1,
h2 {
	color: var(--black);
}

h1 {
	font-size: 96px;
	font-weight: 500;
	margin-bottom: 0;
}

h2 {
	font-size: 24px;
	font-weight: 800;
}

@media only screen and (max-width: 768px) {
	h2 {
		font-size: 18px;
	}
}

strong {
	font-weight: 500;
}

header {
	display: flex;
	width: 100%;
	height: 96px;
	justify-content: space-between;
	align-items: center;
	box-sizing: border-box;
	position: sticky;
	top: 0;
	background-color: var(--white);
	z-index: 3;
	padding: 0 96px;
}

@media only screen and (min-width: 1201px) {
	header {
		padding: 0 96px;
	}
}

@media only screen and (max-width: 1200px) and (min-width: 992px) {
	header {
		padding: 0 60px;
	}
}

@media only screen and (max-width: 991px) and (min-width: 768px) {
	header {
		padding: 0 48px;
	}
}

@media only screen and (max-width: 767px) and (min-width: 576px) {
	header {
		padding: 0 24px;
	}
}

@media only screen and (max-width: 575px) {
	header {
		padding: 0 24px;
		justify-content: center;
	}
}

.logo {
	display: flex;
	flex-direction: column;
	height: 24px;
	font-size: 24px;
	font-weight: 800;
	width: max-content;
	color: var(--black);
}

.logo_under {
	font-size: 12.4px;
	color: var(--black);
	font-weight: 300;
	display: block;
	letter-spacing: 2px;
	width: max-content;
}

.logolink {
	text-decoration: none;
	display: flex;
	flex-direction: column;
	row-gap: 5px;
}

/* - Ikona - persona - */

.person_piotr_box {
	width: 32px;
	height: 32px;
	border-radius: 100%;
	background-image: url("/src/assets/images/personpiotr.png");
	background-color: var(--light);
	transition: 0.3s ease-in-out;
	min-width: 32px;
	max-width: 32px;
	min-height: 32px;
	max-height: 32px;
	background-size: contain;
}

.person_piotr_box:hover {
	background-position: -32px center;
}

.person_box {
	width: 32px;
	height: 32px;
	overflow: hidden;
	border-radius: 100%;
	border: 1px solid var(--black);
	position: relative;
}

.person_box:after {
	content: "";
	position: absolute;
	width: 9px;
	height: 9px;
	border: 1px solid var(--black);
	top: 9px;
	left: 11px;
	border-radius: 100%;
}

.person_box:before {
	content: "";
	position: absolute;
	width: 32px;
	height: 32px;
	border: 1px solid var(--black);
	border-radius: 100%;
	bottom: -24px;
	left: 0px;
}

.progressbar {
	height: 1px;
	background-color: var(--black);
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	top: 95px;
	transform-origin: 0%;
}

nav.mainnav {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	width: 100%;
	height: inherit;
}

nav.mainnav ul {
	padding-left: 0;
	display: flex;
	align-items: center;
	column-gap: 16px;
}

nav.mainnav ul li {
	list-style-type: none;
}

nav.mainnav ul li a {
	text-decoration: none;
	color: var(--black);
}

nav.navprojects {
	position: sticky;
	bottom: 0;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: var(--white);
	height: 96px;
	z-index: 10;
	box-sizing: border-box;
}

.navprojects_box {
	width: 70%;
}

.navprojects_text {
	font-size: 14px;
	color: var(--black);
	width: 30%;
}

@media only screen and (min-width: 1201px) {
	nav.navprojects {
		padding: 0 96px;
	}
}

@media only screen and (max-width: 1200px) and (min-width: 992px) {
	nav.navprojects {
		padding: 0 60px;
	}
}

@media only screen and (max-width: 991px) and (min-width: 768px) {
	nav.navprojects {
		padding: 0 48px;
	}
}

@media only screen and (max-width: 767px) {
	nav.navprojects {
		padding: 0 0 0 72px;
	}

	.navprojects_box {
		width: 100%;
		position: relative;
	}

	.navprojects_box:before {
		content: "";
		position: absolute;
		width: 12px;
		height: 12px;
		background-color: var(--lowlight);
		border-radius: 100%;
		left: -32px;
		bottom: 29px;
	}

	.navprojects_text {
		display: none;
	}
}

nav.navprojects a img {
	width: auto;
	height: 60px;
	border-radius: 100%;
	opacity: 1;
	transition: 0.2s ease-in-out;
}

nav.navprojects a img:hover {
	opacity: 0.8;
}

/**/

section.piotrsection,
section.undersection {
	display: flex;
	flex-direction: column;
}

@media only screen and (min-width: 1201px) {
	section.mainsection {
		margin: 0 96px 144px 96px;
	}

	section.piotrsection {
		margin: 48px 0 72px 0;
	}

	section.undersection {
		margin: 42px 0;
	}
}

@media only screen and (max-width: 1200px) and (min-width: 992px) {
	section.mainsection {
		margin: 0 96px 114px 96px;
		z-index: 1;
		position: relative;
	}

	section.piotrsection {
		margin: 48px 0 42px 0;
	}

	section.undersection {
		margin: 42px 0;
	}
}

@media only screen and (max-width: 991px) and (min-width: 768px) {
	section.mainsection {
		margin: 0 48px 114px 48px;
	}

	section.piotrsection {
		margin: 48px 0 42px 0;
	}

	section.undersection {
		margin: 42px 0;
	}
}

@media only screen and (max-width: 767px) {
	section.mainsection {
		margin: 0 24px 114px 24px;
	}

	section.piotrsection {
		margin: 48px 0 12px 0;
	}

	section.undersection {
		margin: 12px 0;
	}
}

/* - Baner główny - */

.main_box {
	width: 100%;
	display: flex;
	justify-content: center;
	position: relative;
}

.main_box_left {
	width: 50%;
	background-image: url("/src/assets/images/backline.svg");
	height: 440px;
}

.main_box_right {
	width: 50%;
	height: 440px;
	background-image: url("/src/assets/images/backline.svg");
	position: relative;
}

@media only screen and (max-width: 992px) {
	.main_box {
		flex-direction: column;
	}

	.main_box_left {
		width: 100%;
	}

	.main_box_right {
		width: 100%;
	}
}

.main_box_right_gradient {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 440px;
	background: rgb(255, 255, 255);
	background: linear-gradient(
		270deg,
		rgba(255, 255, 255, 1) 0%,
		rgba(255, 255, 255, 0) 100%
	);
}

.main_box_top {
	width: calc(100% - 192px);
	margin: 0 auto;
	height: 500px;
	position: absolute;
	bottom: 0;
	display: flex;
}

.main_box_top_left {
	width: 50%;
	height: 530px;
	background-color: var(--white);
	padding: 48px 60px 72px 48px;
	box-sizing: border-box;
	font-size: 16px;
	line-height: 22px;
	color: var(--black);
	border: 1px solid var(--black);
	border-radius: 16px;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	row-gap: 20px;
}

.main_box_top_left p {
	margin: 0;
	padding: 0;
	-webkit-hyphens: auto;
	-moz-hyphens: auto;
	-ms-hyphens: auto;
	hyphens: auto;
	word-wrap: break-word;
	overflow-wrap: break-word;
}

.main_box_top_left:before {
	content: "";
	position: absolute;
	width: 48px;
	height: 48px;
	background-image: url("/src/assets/images/bubble_triangle.svg");
	right: -48px;
	top: 160px;
}

.main_box_top_left p:first-of-type {
	font-size: 24px;
	font-weight: 800;
}

@media only screen and (max-width: 768px) {
	.main_box_top_left p:first-of-type {
		font-size: 18px;
		font-weight: 800;
	}
}

.main_box_top_right {
	width: 50%;
	height: 500px;
}

@media only screen and (max-width: 1200px) and (min-width: 992px) {
	.main_box_top_left {
		width: inherit;
		height: auto;
		padding: 48px 60px 72px 48px;
		margin: -60px 96px 0 96px;
		z-index: 1;
		font-size: 16px;
		line-height: 22px;
	}

	.main_box_top_right {
		width: 100%;
		height: 440px;
		margin-top: -36px;
		display: flex;
		align-items: flex-end;
		z-index: 2;
	}

	.main_box_top_right img {
		height: 440px;
		width: auto;
		margin-bottom: 1px;
	}

	.main_box_top_left:before {
		right: 50%;
		transform: rotate(90deg);
		bottom: -48px;
		top: unset;
	}
}

@media only screen and (max-width: 991px) and (min-width: 768px) {
	.main_box_top_left {
		width: inherit;
		height: auto;
		padding: 48px 60px 72px 48px;
		margin: -60px 48px 0 48px;
		z-index: 1;
		font-size: 14px;
		line-height: 20px;
	}

	.main_box_top_right {
		width: 100%;
		height: 440px;
		margin-top: -36px;
		display: flex;
		align-items: flex-end;
		z-index: 2;
	}

	.main_box_top_right img {
		height: 440px;
		width: auto;
		margin-bottom: 1px;
	}

	.main_box_top_left:before {
		right: 50%;
		transform: rotate(90deg);
		bottom: -48px;
		top: unset;
	}
}

@media only screen and (max-width: 767px) {
	.main_box_top_left {
		width: inherit;
		height: auto;
		padding: 48px 36px 60px 36px;
		margin: -60px 24px 0 24px;
		z-index: 1;
		font-size: 14px;
		line-height: 20px;
	}

	.main_box_top_right {
		width: 100%;
		height: 340px;
		margin-top: -36px;
		display: flex;
		align-items: flex-end;
		z-index: 2;
	}

	.main_box_top_right img {
		height: 340px;
		width: auto;
		margin-bottom: 1px;
	}

	.main_box_top_left:before {
		right: 48px;
		transform: rotate(90deg);
		bottom: -48px;
		top: unset;
	}
}

@media only screen and (max-width: 1200px) {
	.main_box_top {
		position: relative;
		bottom: 0;
		display: flex;
		flex-direction: column;
		width: auto;
		height: auto;
		background-image: url("/src/assets/images/backline.svg");
	}

	.main_box_left,
	.main_box_right {
		display: none;
	}
}

/**/

.projectsgridpage {
	position: relative;
}

.projectpage {
	width: 100%;
	margin: 0 auto;
}

.projectpageinner {
	width: 100%;
	background-color: var(--white);
	margin: 0 auto;
	padding: 0;
	box-sizing: border-box;
	height: inherit;
}

/* - LISTA PROJEKTÓW - NAWIGACJA - */

nav.projectsgrid {
	width: 100%;
	display: flex;
	justify-content: center;
}

nav.projectsgrid ul {
	display: grid;
	width: 100%;
	margin: 0;
	padding: 0;
	list-style-type: none;
	font-size: 0;
}

@media only screen and (min-width: 1201px) {
	nav.projectsgrid ul {
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
		grid-column-gap: 36px;
		grid-row-gap: 36px;
	}
}

@media only screen and (max-width: 1200px) and (min-width: 991px) {
	nav.projectsgrid ul {
		grid-template-columns: 1fr 1fr 1fr 1fr;
		grid-column-gap: 36px;
		grid-row-gap: 24px;
	}
}

@media only screen and (max-width: 992px) and (min-width: 767px) {
	nav.projectsgrid ul {
		grid-template-columns: 1fr 1fr 1fr;
		grid-column-gap: 24px;
		grid-row-gap: 24px;
	}
}

@media only screen and (max-width: 768px) {
	nav.projectsgrid ul {
		grid-template-columns: 1fr 1fr;
		grid-column-gap: 1px;
		grid-row-gap: 1px;
	}
}

nav.projectsgrid ul li {
	padding: 0;
	margin: 0;
	overflow: hidden;
	position: relative;
}

nav.projectsgrid ul li a {
	width: inherit;
	height: auto;
	overflow: hidden;
	display: block;
	transition: 0.3s ease-in-out;
	position: relative;
	z-index: 2;
	text-decoration: none;
	aspect-ratio: 4/3;
	border-radius: 9px;
}

nav.projectsgrid ul li a span {
	height: 48px;
	font-size: 12px;
	color: var(--black);
	font-weight: 300;
	padding: 12px 12px 12px 36px;
	display: block;
}

nav.projectsgrid ul li a img {
	width: 100%;
	height: auto;
	opacity: 1;
	transition: 0.3s ease-in-out;
}

nav.projectsgrid ul li a img:hover {
	width: 100%;
	height: auto;
	opacity: 0.1;
	transform: scale(1.1);
}

/* - Ukryty div pod kafelkiem - Plus - */

.projectsgrid_goto {
	position: absolute;
	opacity: 0;
	top: 0;
	transition: 0.3s ease-in-out;
	height: inherit;
	width: 100%;
	color: var(--black);
	font-size: 13px;
	font-weight: 500;
	display: flex;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	flex-direction: column;
	z-index: 1;
	transform: scale(1.5);
	aspect-ratio: 4/3;
}

nav.projectsgrid ul li a:hover + .projectsgrid_goto {
	opacity: 1;
	transform: scale(1);
	aspect-ratio: 4/3;
}

.projectsgrid_goto_icon {
	height: 72px;
	width: 72px;
	position: relative;
	transition: 0.2s ease-in-out;
}

.projectsgrid_goto_icon:after {
	content: "";
	position: absolute;
	width: 1px;
	height: 100%;
	background-color: var(--black);
	left: 50%;
	transition: 0.3s ease-in-out;
}

.projectsgrid_goto_icon:before {
	content: "";
	position: absolute;
	height: 1px;
	width: 100%;
	background-color: var(--black);
	top: 50%;
}

/* - Opis pod kafelkiem - */

.projectsgrid_text {
	display: block;
	height: auto;
	font-size: 14px;
	padding: 16px 0 12px 0;
	position: relative;
	color: var(--black);
}

.projectsgrid_text span.strona,
.projectsgrid_text span.sklad,
.projectsgrid_text span.identyfikacja {
	font-size: 10px;
	display: block;
	padding: 6px 6px 0 11px;
	width: auto;
	margin-top: 16px;
	color: var(--black);
	position: relative;
	border-top: 1px solid var(--black);
}

.projectsgrid_text span.strona:before {
	content: "";
	position: absolute;
	left: 0;
	top: 10px;
	width: 6px;
	height: 6px;
	background-image: url("/src/assets/images/wwwicon.svg");
}

.projectsgrid_text span.sklad:before {
	content: "";
	position: absolute;
	left: 0;
	top: 10px;
	width: 6px;
	height: 6px;
	background-image: url("/src/assets/images/skladicon.svg");
}

.projectsgrid_text span.identyfikacja:before {
	content: "";
	position: absolute;
	left: 0;
	top: 10px;
	width: 6px;
	height: 6px;
	background-image: url("/src/assets/images/idicon.svg");
}

[disabled] {
	cursor: default;
}

@media only screen and (max-width: 768px) and (min-width: 576px) {
	.projectsgrid_text {
		font-size: 12px;
		padding: 12px;
	}
}

@media only screen and (max-width: 575px) {
	.projectsgrid_text {
		display: none;
	}
}

/* - Ostatni kafel - tu może być twój projekt - */

.projectsgrid_yourproject {
	display: flex;
	justify-content: center;
	align-items: center;
	aspect-ratio: 4/2.99;
	border: 1px solid var(--black);
	cursor: pointer;
	font-size: 14px;
	color: var(--black);
	position: relative;
	z-index: 3;
	border-radius: 9px;
}

.projectsgrid_yourproject_plus {
	height: 72px;
	width: 72px;
	position: relative;
	transition: 0.2s ease-in-out;
}

.projectsgrid_yourproject_plus:after {
	content: "";
	position: absolute;
	width: 1px;
	height: 100%;
	background-color: var(--black);
	left: 50%;
	transition: 0.3s ease-in-out;
}

.projectsgrid_yourproject_plus:before {
	content: "";
	position: absolute;
	height: 1px;
	width: 100%;
	background-color: var(--black);
	top: 50%;
}

.projectsgrid_yourproject:hover > .projectsgrid_yourproject_plus {
	height: 96px;
	width: 96px;
}

/* - SEKCJA KLIENCI - */

.clients {
	width: 100%;
	/* background-color: var(--superlight); */
	display: flex;
	justify-content: center;
}

.clients_box {
	width: calc(100% - 192px);
	margin: 0 96px;
	/* padding: 60px 0; */
}

.clients_box_slide {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 36px 0;
	height: 60px;
}

.clients_box_slide img {
	height: 120%; /**/
	/* background-color: var(--white);
	padding: 8px 20px;
	border-radius: 6px; */
}

.clients_box .swiper-slide {
	background-color: var(--superlight);
	border-radius: 6px;
}

@media only screen and (max-width: 768px) {
	.clients_box {
		width: 100%;
		margin: 0 0 0 0;
		padding: 0;
	}

	/* .clients_box_slide img {
		background-color: var(--white);
		padding: 8px 20px;
		border-radius: 6px;
	} */
}

/* -  moje narzedzia - */

.mytools {
	width: 100%;
	display: flex;
	justify-content: center;
}

.mytools_box {
	width: calc(100% - 192px);
	padding: 36px 0;
}

@media only screen and (max-width: 768px) {
	.mytools_box {
		width: 100%;
		padding: 0;
	}

	.mytools_box .swiper-slide {
		background-color: var(--superlight);
		border-radius: 6px;
	}
}

.mytools_box_slide {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	padding: 36px 0;
	row-gap: 12px;
	height: 80px;
}

.mytools_box_slide_desc {
	font-size: 12.4px;
	font-weight: 300;
	color: var(--black);
	padding: 0;
	margin: 0;
}

/* - footer - */

footer {
	width: 100%;
	height: 144px;
	background-color: var(--superlight);
	padding: 0 96px;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

footer p {
	margin: 0;
	padding: 0;
	font-size: 16px;
	font-weight: 300;
	color: var(--black);
}

@media only screen and (min-width: 1201px) {
	footer {
		padding: 0 96px;
	}
}

@media only screen and (max-width: 1200px) and (min-width: 992px) {
	footer {
		padding: 0 60px;
	}
}

@media only screen and (max-width: 991px) and (min-width: 768px) {
	footer {
		padding: 0 48px;
	}
}

@media only screen and (max-width: 767px) and (min-width: 576px) {
	footer {
		padding: 0 24px;
		justify-content: center;
		background-color: var(--white);
	}

	footer p {
		font-size: 14px;
	}
}

@media only screen and (max-width: 575px) {
	footer {
		padding: 0 24px;
		justify-content: center;
		height: 96px;
		background-color: var(--white);
	}

	footer p {
		font-size: 14px;
	}
}

/**/

.singleprojectgrid {
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
	position: relative;
}

.singleprojectgrid_box {
	font-size: 0;
	position: relative;
	overflow: hidden;
	margin: 0;
}

.singleprojectgrid_box figcaption {
	position: absolute;
	bottom: 24px;
	left: 24px;
	font-size: 10px;
	margin: 0;
	padding: 0;
	z-index: 2;
	max-width: 180px;
	height: 26px;
	display: flex;
	align-items: end;
}

.singleprojectdesc {
	width: 100%;
	display: grid;
	box-sizing: border-box;
}

.singleprojectdesc p {
	color: var(--black);
	line-height: 21px;
	display: block;
	font-size: 14px;
}

.borderline {
	background-color: var(--black);
	height: 1px;
	box-sizing: border-box;
}

@media only screen and (min-width: 1201px) {
	.singleprojectgrid {
		padding: 0 96px;
	}

	.singleprojectgrid_box {
		width: 50%;
	}

	.singleprojectdesc {
		padding: 32px 96px 60px 96px;
		grid-template-columns: 50% 50%;
	}

	.borderlinebox {
		padding: 0 96px;
		margin-top: 60px;
	}

	.borderlinebox2 {
		padding: 0 96px;
	}
}

@media only screen and (max-width: 1200px) and (min-width: 992px) {
	.singleprojectgrid {
		padding: 0 60px;
	}

	.singleprojectgrid_box {
		width: 50%;
	}

	.singleprojectdesc {
		padding: 32px 60px;
		grid-template-columns: 100%;
	}

	.borderlinebox {
		padding: 0 60px;
		margin-top: 48px;
	}

	.borderlinebox2 {
		padding: 0 60px;
	}
}

@media only screen and (max-width: 991px) and (min-width: 768px) {
	.singleprojectgrid {
		padding: 0 48px;
	}

	.singleprojectgrid_box {
		width: 50%;
	}

	.singleprojectdesc {
		padding: 24px 48px;
		grid-template-columns: 100%;
	}

	.borderlinebox {
		padding: 0 48px;
		margin-top: 48px;
	}

	.borderlinebox2 {
		padding: 0 60px;
	}
}

@media only screen and (max-width: 767px) {
	.singleprojectgrid {
		padding: 0;
	}

	.singleprojectgrid_box {
		width: 100%;
	}

	.singleprojectgrid_box figcaption {
		max-width: 100%;
		width: 100%;
		justify-content: center;
		left: 0;
		bottom: 12px;
	}

	.singleprojectdesc {
		padding: 12px 24px;
		grid-template-columns: 100%;
	}

	.borderlinebox {
		padding: 0 24px;
		margin-top: 48px;
	}

	.borderlinebox2 {
		display: none;
	}
}

.singleprojectgrid_box img {
	width: 100%;
	transition: 0.2s ease-in-out;
	position: static;
	z-index: 1;
}

.singleprojectgrid_box img:hover {
	transform: scale(1.02);
}

.singlephoto {
	padding: 0 96px;
}

.singlephoto img {
	width: 100%;
	height: auto;
}

.circle {
	position: fixed;
	z-index: 100;
	display: flex;
	flex-flow: row;
	align-content: center;
	justify-content: center;
	top: 0;
	left: 0;
	height: 10px;
	width: 10px;
	background-color: #1e91d6;
	border-radius: 200px;
	pointer-events: none;
	color: #fff;
	text-align: center;
	font-size: 16px;
}

.cursorText {
	flex: auto;
	font-size: inherit;
	pointer-events: none;
	margin: auto;
}

.container {
	display: flex;
	flex-flow: row;
	height: 90vh;
	width: 98vw;
	background: #fff;
	border-radius: 20px;
	padding: 40px;
}

.project {
	display: inline-block;
	position: relative;
	z-index: 1;
	height: 652px;
	width: 601px;
	background: #1e91d6;
	border-radius: 24px;
	cursor: none;
	background-image: url("https://cdn.discordapp.com/attachments/577203676108685331/790326977714192465/Tile_Image.png");
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}

.projectName {
	background: #fff;
	border-radius: 34px;
	position: absolute;
	bottom: 30px;
	left: 30px;
	padding: 12px 20px;
	display: inline-block;
	font-weight: 500;
	font-size: 16px;
}

.contactWrapper {
	padding: 120px;
}

.contact {
	font-weight: 500;
	font-size: 36px;
	line-height: 1.2;
	text-decoration: underline;
	transition: 150ms ease;
}

.contact:hover {
	color: #1e91d6;
	cursor: pointer;
}

/* - Placeholder loader - */

#placeholder_spinner {
	width: 36px;
	height: 36px;
	border: 1px solid var(--white);
	border-top: 1px solid var(--black);
	border-radius: 50%;
}

#placeholder_spinner {
	-webkit-transition-property: -webkit-transform;
	-webkit-transition-duration: 1.2s;
	-webkit-animation-name: rotate;
	-webkit-animation-iteration-count: infinite;
	-webkit-animation-timing-function: linear;

	-moz-transition-property: -moz-transform;
	-moz-animation-name: rotate;
	-moz-animation-duration: 1.2s;
	-moz-animation-iteration-count: infinite;
	-moz-animation-timing-function: linear;

	transition-property: transform;
	animation-name: rotate;
	animation-duration: 1.2s;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
}

@-webkit-keyframes rotate {
	from {
		-webkit-transform: rotate(0deg);
	}
	to {
		-webkit-transform: rotate(360deg);
	}
}

@-moz-keyframes rotate {
	from {
		-moz-transform: rotate(0deg);
	}
	to {
		-moz-transform: rotate(360deg);
	}
}

@keyframes rotate {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

/**/

@media only screen and (max-width: 767px) {
	.mobilehide {
		display: none;
	}
}

.closebutton {
	width: 72px;
	height: 72px;
	display: block;
	position: fixed;
	z-index: 10;
	background-color: var(--white);
	top: 0;
	right: 96px;
	overflow: hidden;
	transition: 0.2s ease-in-out;
	border-bottom-left-radius: 9px;
}

.closebutton:after {
	content: "";
	position: absolute;
	width: 1px;
	height: 60%;
	background-color: var(--black);
	top: 21%;
	transform: rotate(45deg);
	left: 50%;
	transition: 0.2s ease-in-out;
}

.closebutton:before {
	content: "";
	position: absolute;
	height: 1px;
	width: 60%;
	background-color: var(--black);
	top: 50%;
	left: 21%;
	transform: rotate(45deg);
	transition: 0.2s ease-in-out;
}

.closebutton:hover {
	background-color: var(--black);
}

.closebutton:hover:after,
.closebutton:hover:before {
	background-color: var(--white);
	transform: rotate(-45deg);
}

@media only screen and (max-width: 1200px) and (min-width: 992px) {
	.closebutton {
		width: 72px;
		height: 72px;
		right: 60px;
	}
}

@media only screen and (max-width: 991px) and (min-width: 768px) {
	.closebutton {
		width: 60px;
		height: 60px;
		right: 48px;
	}
}

@media only screen and (max-width: 767px) and (min-width: 576px) {
	.closebutton {
		width: 60px;
		height: 60px;
		right: 0;
	}
}

@media only screen and (max-width: 575px) {
	.closebutton {
		width: 48px;
		height: 48px;
		right: 0;
	}
}

.marker {
	position: relative;
	z-index: 1;
	display: inline-block;
	text-decoration: none;
	color: var(--black);
}

.marker:before {
	content: "";
	position: absolute;
	width: 100%;
	height: 7px;
	background-color: var(--marker);
	bottom: 3px;
	z-index: -1;
	display: block;
	transition: 0.2s ease-in-out;
}

.marker:hover:before {
	bottom: 2px;
	height: 1px;
	background-color: var(--lowlight);
}
